figure.rollover {
  position: relative;
  margin: 1em;
  cursor: hand;
  cursor: pointer;
  * {
    transition: all 0.33s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
  }
  figcaption {
    position: absolute;
    bottom: 5%;
    left: 5px;
    right: 5px;
    color: white;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    opacity: 0;
  }
  &:hover {
    figcaption {
      opacity: 1;
    }
    img {
      opacity: 0.3;
      transform: scale(1.1);
    }
  }
}

.rollover-popup {
  position: relative;
  background: #fff;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  border:2px solid red;
}

@media (max-width: 960px) {
  figure.rollover figcaption {
    font-size: 20px;
    line-height: 18.5px;
  }
}
@media (max-width: 768px) {
  figure.rollover figcaption {
    display: none;
    opacity: 1;
    font-size: 18px;
    line-height: 28.5px;
  }
}


.mfp-wrap {
  background: rgba(#000, .8);
  opacity: 0;
}
.mfp-wrap {
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-wrap.mfp-ready {
  opacity: 1;
}

.mfp-wrap.mfp-removing {
  opacity: 0;
}

.mfp-title {
  line-height: 22px;
}