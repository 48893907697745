$mouseColor: #fff;

.mouse-scroll {
  display: block;
  position: fixed;
  margin: auto;
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
  z-index: 9999;
}
.mouse-scroll__mouse {
  height: 40px;
  width: 30px;
  border-radius: 10px;
  transform: none;
  border: 2px solid $mouseColor;
  border: 2px solid rgba($mouseColor, .7);
  top: 170px;
}
.mouse-scroll__mouse-in {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: $mouseColor;
  background: rgba($mouseColor, .7);
  position: relative;
  animation: mouse-scroll-move 1.2s ease infinite;
}

.mouse-scroll__arrow {
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-right: 2px solid $mouseColor;
  border-bottom: 2px solid $mouseColor;
  border-right: 2px solid rgba($mouseColor, .7);
  border-bottom: 2px solid rgba($mouseColor, .7);
  margin:0 0 3px 10px;
  animation: mouse-scroll-arrows 3s infinite;
  animation-direction: alternate;
  opacity: 0;
}
.mouse-scroll__arrow__1 {
  margin-top: 6px;
  animation-delay: .1s;
}
.mouse-scroll__arrow__2 {
  animation-delay: .2s;
}
.mouse-scroll__arrow__3 {
  animation-delay: .3s;
}


@keyframes mouse-scroll-move {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(4px);
  }
  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

@keyframes mouse-scroll-arrows {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
